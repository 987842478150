<script>
import utilsMixin from '@shared/mixins/utils';
import { mapGetters, mapState } from 'vuex';

export default {
  mixins: [utilsMixin],
  computed: {
    ...mapState('store', {
      store: 'data',
    }),
    ...mapGetters('auth', {
      authCustomer: 'customer',
    }),
    commissionRate() {
      let amount = this.store.affiliation_options.default_commission_amount;
      let percentage = this.store.affiliation_options.default_commission_percentage;
      const applications = this.authCustomer?.affiliate_data?.applications;

      if (applications?.store) {
        const hasSpecific = !!(
          applications.store_amount
          || applications.store_percentage
        );

        amount = hasSpecific ? applications.store_amount : amount;
        percentage = hasSpecific ? applications.store_percentage : percentage;
      }

      return amount ? `${amount}€` : `${percentage}%`;
    },
  },
};
</script>

<template>
  <ul>
    <li>
      Commission par vente (sauf cas spécifique) :
      <strong>{{ commissionRate }}</strong>

      <b-tooltip multilined>
        <template #content>
          <p>
            C’est le montant ou le pourcentage qui vous sera reversé
            en cas de vente via vos liens d’affiliation.
          </p>
          <p class="mt-3">
            Le formateur peut aussi définir ces commissions au cas par cas.
          </p>
          <p class="mt-3">
            Vous pourrez vérifier les commissions qui s'appliquent
            pour vos liens dans Affiliation > Profil & liens.
          </p>
        </template>
        <b-icon pack="far" icon="question-circle" />
      </b-tooltip>
    </li>
    <li>
      Durée de validité du lien de tracking :
      <strong>
        {{ store.affiliation_options.max_tracking_duration }} jour{{ store.affiliation_options.max_tracking_duration > 1 ? 's' : '' }}
      </strong>

      <b-tooltip multilined>
        <template #content>
          Lorsqu'une visite est générée depuis un de vos liens d'affiliation,
          nous vous attribuons la vente si cette dernière intervient
          pendant la durée de validité du tracking.
        </template>
        <b-icon pack="far" icon="question-circle" />
      </b-tooltip>
    </li>
    <li>
      Délai minimum avant demande de règlement :
      <strong>
        {{ store.affiliation_options.min_payout_delay }} jour{{ store.affiliation_options.min_payout_delay > 1 ? 's' : '' }}
      </strong>

      <b-tooltip multilined>
        <template #content>
          C’est le délai minimum entre le moment
          où vous générez une commission et le moment où vous pouvez
          demander le règlement de cette commission.
        </template>
        <b-icon pack="far" icon="question-circle" />
      </b-tooltip>
    </li>
    <li>
      Montant minimum de règlement :
      <strong>
        {{ store.affiliation_options.min_payout_amount | formatPrice }}
      </strong>

      <b-tooltip multilined>
        <template #content>
          C’est le montant minimum de commissions générées que
          vous devez atteindre pour demander leur règlement.

        </template>
        <b-icon pack="far" icon="question-circle" />
      </b-tooltip>
    </li>
    <li>
      Règle d'attribution des ventes :
      <strong
        v-if="store.affiliation_options.referer_priority === 'first'"
      >
        premier lien cliqué
      </strong>
      <strong v-else>
        dernier lien cliqué
      </strong>

      <b-tooltip multilined>
        <template #content>
          Si une vente est conclue suite à des visites depuis plusieurs liens d’affiliation,
          c’est la règle qui permet d’attribuer la vente à un des affiliés.
        </template>
        <b-icon pack="far" icon="question-circle" />
      </b-tooltip>
    </li>
  </ul>
</template>
