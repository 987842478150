<script>
import { mapGetters, mapState } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import StatisticCard from '@shared/components/StatisticCard.vue';
import AffiliateConditionList from '@school/components/AffiliateConditionList.vue';

export default {
  mixins: [utilsMixin],
  components: { StatisticCard, AffiliateConditionList },
  i18n: {
    messages: {
      fr: {
        stat_description: {
          total_amount: "Montant total des commissions générées depuis vos liens d'affiliation",
          total_pending: "Montant en attente d'exigibilité de vos commissions",
          total_available: 'Montant exigible de vos commissions',
          total_sales: "Nombre de ventes générées depuis vos liens d'affiliation",
          total_visits: "Nombre de visites générées depuis vos liens d'affiliation",
          total_conversion: "Pourcentage des visites ayant généré des ventes depuis vos liens d'affiliation",
        },
      },
    },
  },
  data: () => ({
    isFetching: false,
  }),
  computed: {
    ...mapState('store', {
      store: 'data',
    }),
    ...mapState('auth', {
      affiliate: 'affiliateData',
    }),
    ...mapGetters('auth', {
      authCustomer: 'customer',
    }),
    hasAffiliationDisabled() {
      return (
        this.$store.getters['store/hasPercentPlan']
        || this.$store.getters['store/isAffiliationClosed']
      );
    },
  },
  methods: {
    fetchData() {
      if (this.authCustomer.affiliate_data) {
        this.isFetching = true;
        this.$store.dispatch('auth/getAffiliateData')
          .finally(() => (this.isFetching = false));
      }
    },
    join() {
      const loader = this.$buefy.loading.open();
      this.$store.dispatch('auth/joinAffiliates')
        .finally(() => {
          loader.close();
          window.scroll(0, 0);
        });
    },
    redirectIfNotAffiliate() {
      const cannotSeeAffiliate = (
        !this.$store.getters['store/isAffiliationPublic']
        && !this.authCustomer?.affiliate_data
      );

      if (cannotSeeAffiliate) {
        this.$router.push({
          name: this.authCustomer ? 'dashboard' : 'login',
        });
      }
    },
  },
  created() {
    this.redirectIfNotAffiliate();
    this.fetchData();
  },
};
</script>

<template>
  <div class="section">
    <aside
      v-if="hasAffiliationDisabled"
      class="notification is-warning"
    >
      <b-icon icon="exclamation-triangle" />
      Ce programme d’affiliation est actuellement fermé.
      Les visites et les ventes depuis les liens d’affiliation ne sont pas comptabilisées
      et aucune nouvelle commission ne pourra être générée.
      Vous pouvez en revanche toujours demander le règlement de vos commissions déjà générées.
    </aside>

    <template v-if="authCustomer.affiliate_data">
      <section class="columns is-mobile is-multiline">
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              Visites
            </template>
            <template #default>
              {{ affiliate.total_visits }}
            </template>
            <template #tooltipContent>
              <span v-t="'stat_description.total_visits'" />
            </template>
          </StatisticCard>
        </div>
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              Ventes
            </template>
            <template #default>
              {{ affiliate.total_sales }}
            </template>
            <template #tooltipContent>
              <span v-t="'stat_description.total_sales'" />
            </template>
          </StatisticCard>
        </div>
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              Taux de conversion
            </template>
            <template #default>
              {{
                [affiliate.total_sales, affiliate.total_visits] | toPercentage
              }}%
            </template>
            <template #tooltipContent>
              <span v-t="'stat_description.total_conversion'" />
            </template>
          </StatisticCard>
        </div>
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              Total des commissions
            </template>
            <template #default>
              {{ affiliate.total_amount | formatPrice }}
            </template>
            <template #tooltipContent>
              <span v-t="'stat_description.total_amount'" />
            </template>
          </StatisticCard>
        </div>
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              Montant disponible
            </template>
            <template #default>
              {{ affiliate.total_available | formatPrice }}
            </template>
            <template #tooltipContent>
              <span v-t="'stat_description.total_available'" />
            </template>
          </StatisticCard>
        </div>
        <div class="column is-6-touch is-4-desktop">
          <b-skeleton v-if="isFetching" height="150" />
          <StatisticCard v-else>
            <template #title>
              Montant en attente
            </template>
            <template #default>
              {{ affiliate.total_pending | formatPrice }}
            </template>
            <template #tooltipContent>
              <span v-t="'stat_description.total_pending'" />
            </template>
          </StatisticCard>
        </div>
      </section>

      <nav>
        <div class="tabs mb-10">
          <ul>
            <router-link
              tag="li"
              :to="{name: 'affiliate', params: $route.params}"
              exact-active-class="is-active"
            >
              <a>Tableau de bord</a>
            </router-link>
            <router-link
              tag="li"
              :to="{name: 'affiliate_profile', params: $route.params}"
              exact-active-class="is-active"
            >
              <a>Profil et liens</a>
            </router-link>
            <router-link
              tag="li"
              :to="{name: 'affiliate_payouts', params: $route.params}"
              exact-active-class="is-active"
            >
              <a>Demandes de règlement</a>
            </router-link>
          </ul>
        </div>
      </nav>

      <router-view />
    </template>
    <section v-else class="box mw-640 mx-auto content">
      <h1>Programme d'affiliation</h1>

      <p>
        Bienvenue dans le programme d'affiliation de
        <strong>{{ store.name }}</strong>. Vous pouvez rejoindre ce programme
        en un clic pour commencer à percevoir des commissions grâce à vos recommandations.
      </p>

      <h2>
        Qu'est-ce que l'affiliation ?
      </h2>

      <p>
        L’affiliation est un système qui récompense les recommandations et le bouche à oreille.
        En rejoignant le programme d’affiliation, nous vous communiquons des liens de
        tracking qui vous serviront à recommander les offres de {{ store.name }}.
      </p>
      <p>
        Lorsqu’une ou plusieurs ventes sont générées depuis vos liens d’affiliation,
        vous percevez une commission sur ces ventes !
      </p>
      <p>
        C’est sans engagement de votre part :
        vous n’avez aucune obligation de résultat et pouvez interrompre votre
        participation au programme à tout moment.
      </p>

      <h2>
        Quelles sont les conditions du programme ?
      </h2>

      <AffiliateConditionList />

      <div
        v-if="store.affiliation_options.description"
        class="mb-4"
        v-html="store.affiliation_options.description"
      />

      <p class="has-text-weight-bold">
        Les conditions peuvent changer à tout moment,
        pour tous les affiliés ou pour votre compte spécifiquement.
      </p>

      <p>
        <b-button type="is-primary is-custom" @click="join">
          Rejoindre le programme d'affiliation
        </b-button>
      </p>
    </section>
  </div>
</template>
